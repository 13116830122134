import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { resolveSessionStorageData } from './SessionStorageAdapter/SessionStorageResolver';
import { resolveURLData } from './URLAdapter/URLDataResolver';
import { DynamicPriceInfo } from '@wix/bookings-uou-types';
import { ExperimentsConsts } from '../../consts/experiments';

export type PageAPIData = {
  serviceId: string;
  timezone: string;
  slotAvailability?: SlotAvailability;
  dynamicPricePreSelection?: DynamicPriceInfo;
  isEcom?: boolean;
};

export const getPageAPIData = async ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}) => {
  const { experiments } = flowAPI;

  const isURLAPI = experiments.enabled(ExperimentsConsts.FormPageURLAPI);

  try {
    const pageData = isURLAPI
      ? await resolveURLData({
          flowAPI,
        })
      : await resolveSessionStorageData({
          flowAPI,
        });

    return pageData;
  } catch (error) {
    console.error('ERROR: failed to resolve page API data', error);
    return undefined;
  }
};
