import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { PaymentType } from '@wix/bookings-uou-types';
import { ActiveFeatures } from '@wix/bookings-uou-types/src';
import { hasOnlinePaymentMethods } from '../../api/hasOnlinePayments';
import { Service } from '../mappers/service.mapper';

export interface CanBookResponse {
  canBook: boolean;
  reason?: {
    premiumError: boolean;
    pricingPlanError: boolean;
    isServiceConnectedToPricingPlans: boolean;
    isPricingPlanInstalled: boolean;
  };
}

export const Features = {
  PAYMENTS: 'payments',
  INDIVIDUAL: 'individual',
  GROUP: 'groups',
  COURSE: 'course',
  REMINDERS: 'reminders',
  SMS_REMINDERS: 'smsReminders',
  STAFF: 'staffMembers',
};

export class DayfulBookValidations {
  constructor(
    private readonly wixSdkAdapter: WixOOISDKAdapter,
    private readonly service: Service,
    private readonly activeFeatures: ActiveFeatures,
  ) {}

  async canBook() {
    const isPremium = await this.isPremium();

    return {
      canBook: isPremium || (this.isOffline() && this.hasNoPricingPlans()),
      reason: {
        premiumError: !isPremium,
      },
    };
  }

  private isOffline() {
    return (
      this.service.payment.paymentDetails.paymentType === PaymentType.OFFLINE
    );
  }

  private hasNoPricingPlans() {
    return (
      (this.service.payment.pricingPlanInfo?.pricingPlans?.length || 0) === 0
    );
  }

  private isOnline() {
    return (
      this.service.payment.paymentDetails.paymentType === PaymentType.ONLINE
    );
  }

  private isPremium() {
    return this.isFeatureEnabled() && this.applicableForPayments();
  }

  private applicableForPayments() {
    if (this.isOnline()) {
      const accountId = `${this.wixSdkAdapter.getAppDefId()}:${this.wixSdkAdapter.getInstanceId()}`;
      return hasOnlinePaymentMethods({ accountId });
    } else if (this.isOffline() && !this.hasNoPricingPlans()) {
      return false;
    }
    return true;
  }

  private isFeatureEnabled() {
    const featureName = Features[this.service.type];
    if (this.activeFeatures.hasOwnProperty(featureName)) {
      // @ts-expect-error
      return this.activeFeatures[featureName];
    }
    return false;
  }
}
