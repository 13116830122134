import { IUser } from '@wix/native-components-infra/dist/src/types/types';
import { FormErrors, GenericErrorType } from '../../../../types/errors';
import { CreateActionParams } from '../actions';
import { bookingsLoginClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { getDefaultPaymentOptionId } from '../../../../utils/payment/payment';
import { FormStatus } from '../../../../types/form-state';
import { ServiceType } from '@wix/bookings-uou-types';

export type OnLogin = (user?: IUser) => Promise<void>;

export function createOnLoginAction({
  getControllerState,
  context,
  internalActions,
}: CreateActionParams): OnLogin {
  return async (user) => {
    const [state, setState] = getControllerState();
    const {
      slotAvailability,
      isPricingPlanInstalled,
      isBookingsOnEcom,
      service,
    } = state;
    const { serviceId, startDate } = slotAvailability.slot!;
    const { formApi, settings, wixSdkAdapter, biLogger, reportError } = context;
    const { errorHandlers } = internalActions;
    const isCourse = service.type === ServiceType.COURSE;

    biLogger?.report(bookingsLoginClick({}));

    wixSdkAdapter.removeFromSessionStorage(BookingsQueryParams.FILLED_FIELDS);

    const onError = (error: FormErrors) => errorHandlers.addError(error);

    if (user) {
      try {
        const [
          memberDetails,
          pricingPlanDetails,
          memberships,
        ] = await Promise.all([
          formApi.getMemberDetails({
            id: user.id,
            onError,
          }),
          !isBookingsOnEcom && !isCourse
            ? formApi.getPricingPlanDetails({
                serviceId: serviceId!,
                startTime: startDate!,
                onError,
              })
            : undefined,
          isBookingsOnEcom && !isCourse
            ? formApi.listMemberships({
                serviceId: serviceId!,
                startTime: startDate!,
                onError,
              })
            : undefined,
        ]);

        const selectedPaymentOptionId = getDefaultPaymentOptionId({
          settings,
          servicePayment: state.service.payment,
          pricingPlanDetails,
          memberships,
          isPricingPlanInstalled,
        });

        setState({
          memberDetails,
          memberships,
          pricingPlanDetails,
          selectedPaymentOptionId,
          status: FormStatus.IDLE,
          overrideDefaultFieldsValues: true,
        });
      } catch (error) {
        errorHandlers.addError(error as any);
        setState({ status: FormStatus.IDLE });
        reportError(error as any);
      }
    } else {
      errorHandlers.addError(GenericErrorType.GENERIC_MEMBER_DETAILS_ERROR);
      reportError(GenericErrorType.GENERIC_MEMBER_DETAILS_ERROR);
    }
  };
}
