// evid 16:636
export enum ReferralInfo {
  AddMoreSessions = 'add more sessions',
}

// evid 16:659
export enum FormClickNextAction {
  ChooseAPlan = 'choose a plan',
  AddToCart = 'add to cart',
}

// evid 16:1052
export enum FormClickAction {
  Payment = 'payment',
  Login = 'login',
  Back = 'back',
}

export enum FormClickActionValue {
  PricingPlan = 'pricing plan',
  SingleSessions = 'single sessions',
}

// evid 16:1072
export enum CartModalInteractionActionName {
  Load = 'load',
  GoToCheckout = 'go to checkout',
  AddMoreSessions = 'add more sessions',
  Close = 'close',
}
