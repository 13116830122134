import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getURLAdapter } from './URLAdapter';

export const getInputServiceType = ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): 'class' | 'course' | 'appointment' | undefined => {
  const urlAdapter = getURLAdapter({ flowAPI });

  const sessionId = urlAdapter.getSessionId();
  const serviceId = urlAdapter.getServiceId();
  const resourceId = urlAdapter.getResourceId();
  const startDate = urlAdapter.getStartDate();
  const endDate = urlAdapter.getEndDate();

  if (sessionId) {
    return 'class';
  }

  if (serviceId && !(resourceId && startDate && endDate)) {
    return 'course';
  }

  if (serviceId && resourceId && startDate && endDate && !sessionId) {
    return 'appointment';
  }

  return undefined;
};
