import { SelectedPaymentOption } from '@wix/ambassador-bookings-gateway/types';
import { CreateActionParams } from '../actions';
import { bookingsUouBookingsFormClicks } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  FormClickAction,
  FormClickActionValue,
} from '../../../../types/biLoggerTypes';

export type setSelectedPaymentType = (
  selectedPaymentType: SelectedPaymentOption,
) => void;

export function createSetSelectedPaymentTypeAction({
  getControllerState,
  internalActions: { calculatePaymentDetails },
  context: { biLogger },
}: CreateActionParams): setSelectedPaymentType {
  return async (selectedPaymentType: SelectedPaymentOption) => {
    const [state, setState] = getControllerState();

    setState({ selectedPaymentType });
    biLogger?.report(
      bookingsUouBookingsFormClicks({
        action: FormClickAction.Payment,
        value:
          selectedPaymentType === SelectedPaymentOption.MEMBERSHIP
            ? FormClickActionValue.PricingPlan
            : FormClickActionValue.SingleSessions,
      }),
    );

    await calculatePaymentDetails({
      couponCode: state.couponInfo.appliedCoupon?.couponCode,
    });
  };
}
