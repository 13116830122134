import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import {
  ServiceOptionType,
  ServiceOptionsAndVariants,
} from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { DynamicPriceInfo as DynamicPriceInfoStorage } from '@wix/bookings-uou-types';
import { Service } from '../mappers/service.mapper';
import { FormApi } from '../../api/FormApi';
import {
  DynamicPriceInfo,
  SelectedPreferences,
} from '../../types/dynamicPrice';
import {
  selectedVariantsAndServiceOptionsToCustomOptions,
  isPreferenceOption,
} from '../mappers/dynamic-price.mapper';

export interface InitiateDynamicPriceState {
  formApi: FormApi;
  service: Service;
  dynamicPricePreSelection?: DynamicPriceInfoStorage;
  isCustomOptionsEnabled: boolean;
  wixSdkAdapter: WixOOISDKAdapter;
  dateRegionalSettingsLocale: string;
  serviceOptionsAndVariants?: ServiceOptionsAndVariants;
}

export const initiateDynamicPriceState = async ({
  formApi,
  service,
  dynamicPricePreSelection,
  isCustomOptionsEnabled,
  wixSdkAdapter,
  dateRegionalSettingsLocale,
  serviceOptionsAndVariants,
}: InitiateDynamicPriceState): Promise<DynamicPriceInfo | undefined> => {
  if (
    !serviceOptionsAndVariants ||
    !service?.payment?.paymentDetails?.isVariedPricing
  ) {
    return;
  }

  const selectedPreferences = createSelectedPreferences(
    service,
    serviceOptionsAndVariants,
  );

  const customOptions = isCustomOptionsEnabled
    ? selectedVariantsAndServiceOptionsToCustomOptions({
        selectedVariants: dynamicPricePreSelection?.selectedVariants,
        serviceOptions: serviceOptionsAndVariants?.options,
        serviceVariants: serviceOptionsAndVariants?.variants,
        dateRegionalSettingsLocale,
      })
    : [];

  const isDynamicPreferenceType = isPreferenceOption(
    serviceOptionsAndVariants?.options!,
  );

  return {
    serviceOptionsAndVariants,
    defaultPrice: {
      amount: service.payment.paymentDetails.price?.toString(),
      currency: service.payment.paymentDetails.currency,
    },
    customOptions,
    isDynamicPreferenceType,
    selectedVariants: dynamicPricePreSelection?.selectedVariants,
    ...(selectedPreferences?.length ? { selectedPreferences } : {}),
  };
};

const createSelectedPreferences = (
  service: Service,
  serviceOptionsAndVariants?: ServiceOptionsAndVariants,
): SelectedPreferences[] => {
  const selectedPreferences: SelectedPreferences[] = [];

  serviceOptionsAndVariants?.options?.values?.forEach((option) => {
    switch (option.type) {
      case ServiceOptionType.STAFF_MEMBER: {
        return selectedPreferences.push({
          type: ServiceOptionType.STAFF_MEMBER,
          id: service.staffMembers?.[0]?.id,
        });
      }
    }
  });

  return selectedPreferences;
};
